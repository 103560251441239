@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import 'antd/dist/reset.css';

:root {
  --primary-color: #68adf8;
  --secondary-color: #4b9df5;
  --grey: #b9b9b9;
}

* {
  font-family: 'Montserrat', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  line-height: min(25px);
}

div {
  line-height: min(25px);
  font-size: 14px;
}

p {
  font-family: 'Montserrat', sans-serif;
  line-height: min(25px);
}

h1 {
  font-family: 'Montserrat', sans-serif;
  line-height: initial;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  line-height: initial;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  line-height: initial;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  line-height: initial;
}

.editorClassName.rdw-editor-main {
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -5px;
  padding: 0 15px;
  height: 200px;
}

.column .ant-form-item-control-input-content {
  flex-direction: column;
  display: flex;
}


.sticky {
  padding-top: 60px !important;
}

.sticky .navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 1px 0 3px rgb(226, 226, 226);
  height: 60px;
}